import React, {useEffect, useRef, useState} from 'react';

export default function App() {
    return (
        <div id="app" className="bg-cyan-950 text-white text-xl min-h-screen flex items-center justify-center">
            <MainContainer/>
        </div>
    );
}

function MainContainer() {
    const [movieRecommendations, setMovieRecommendations] = useState([]);
    const moviesListRef = useRef(null);
    const [shouldScroll, setShouldScroll] = useState(false);

    useEffect(() => {
        if (shouldScroll && moviesListRef.current) {
            moviesListRef.current.scrollIntoView({behavior: 'smooth'});
            setShouldScroll(false); // Reset the scroll indicator
        }
    }, [shouldScroll]);

    return (
        <div id="outterContainer"
             className="w-full md:w-1/3 bg-cyan-90 flex flex-col justify-center items-center rounded-2xl p-0 bg-opacity-50">
            <div id="innerContainer" className="bg-cyan-900 rounded-2xl p-6">
                <span className="text-7xl mb-4">🎬</span>
                <SearchForm setMovieRecommendations={setMovieRecommendations} moviesListRef={moviesListRef}
                            setShouldScroll={setShouldScroll}/>
            </div>

            {movieRecommendations.length > 0 &&
                <RecommendationsList ref={moviesListRef} movies={movieRecommendations}/>}

        </div>
    );
}

const RecommendationsList = React.forwardRef(({movies}, ref) => {
    return (
        <div ref={ref} className="mt-12 w-full px-6">
            {movies.map((movie, index) => (
                <div key={movie.title}>
                    {index > 0 && <hr className="border-t-2 border-cyan-800 my-12 w-full"/>}
                    <MovieItem movie={movie}/>
                </div>
            ))}
        </div>
    );
});


function MovieItem({movie}) {
    return (
        <div className="my-4 flex mb-12">
            <img src={movie.picture} alt={movie.title} className="rounded-lg w-32 h-48 object-cover mr-4"/>
            <div>
                <h3 className="font-normal text-2xl">{movie.title} ({movie.year})</h3>
                <p className="mt-2">{movie.description}</p>
                <p>Rating: {movie.rating}</p>
                <a href={movie.trailer} target="_blank" rel="noopener noreferrer"
                   className="text-cyan-400 hover:underline mt-1">Watch Trailer</a>
            </div>
        </div>
    );
}


function SearchForm({
                        setMovieRecommendations,
                        setShouldScroll
                    }) {

    const [genre, setGenre] = useState("");
    const [similarMovies, setSimilarMovies] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            genre: genre,
            similarMovies: similarMovies
        };

        try {
            const response = await fetch("https://movies2.free.beeceptor.com/recommendations", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            setMovieRecommendations(responseData); // Update the movie recommendations state
            setShouldScroll(true);  // Indicate that we should scroll to the list

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };
    return (
        <div className="searchForm">
            <form onSubmit={handleSubmit}>
                <div>
                    <label className="font-normal inline-block m-2">Genre: </label>
                    <input
                        type="text"
                        className="text-slate-400 text-2xl w-full h-12 p-4 rounded-lg mb-2"
                        placeholder="Enter your preferred movie genre"
                        onChange={(e) => setGenre(e.target.value)}
                    />
                </div>
                <div>
                    <label className="font-normal inline-block m-2">Similar Movies: </label>
                    <input
                        type="text"
                        className="text-slate-400 text-2xl w-full h-12 p-4 rounded-lg mb-2"
                        placeholder="Enter a movie you've enjoyed"
                        onChange={(e) => setSimilarMovies(e.target.value)}
                    />
                </div>
                <div>
                    <button type="submit"
                            className="bg-cyan-500 hover:bg-cyan-600 text-white mt-3 font-semibold py-2 px-4 rounded-lg">
                        Search
                    </button>
                </div>
            </form>
        </div>
    );
}